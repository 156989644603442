import barba from '@barba/core';
import { gsap, ScrollTrigger, DrawSVGPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

import Header from "./header";
import NavMobile from "./nav-mobile";
import Slider from "./slider";
import Reveal from "./reveal";

export default class BarbaJs {

  constructor() {
        const body = document.querySelector('body');

    barba.init({
      timeout: 6000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },


      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active') || el.target == '_blank'),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Header();
              new NavMobile();
              new Reveal('.js--reveal');
              new Slider('.js--slider');
            }, 1);


            const heroEmblem = document.getElementById('hero-emblem');
            if (heroEmblem) {
              gsap.to(heroEmblem, { duration: 0, opacity: 0 });
              setTimeout(() => {
                gsap.to(heroEmblem, { duration: 0.5, opacity: 1 });
                gsap.from(heroEmblem.querySelectorAll('svg > *'), { duration: 2, stagger: 0.01, drawSVG: 0 });
              }, 2000);
            }


            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.5,
            });
          },


          beforeLeave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: data.trigger.classList !== undefined && data.trigger.classList.contains('navigation--header-link') ? 0.5 : 0.4,
            });
          },


          afterLeave(data) {
            window.scrollTo({
              top: 0,
              left: 0,
            });

            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },


          beforeEnter(data) {
            data.next.container.style.opacity = 0;
          },


          enter(data) {
            data.current.container.remove();
            window.dispatchEvent(new Event('resize'));

            // reinit JS
            setTimeout(() => {
              new Header();
              new NavMobile();
              new Reveal('.js--reveal');
              new Slider('.js--slider');
            }, 1);

          },

          afterEnter(data) {
            body.classList.remove('navigation__inverted');
            const heroEmblem = document.getElementById('hero-emblem');
            if (heroEmblem) {
              gsap.to(heroEmblem, { duration: 0, opacity: 0 });
            }

            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 0.75,
              onComplete: () => {
                if (heroEmblem) {
                  gsap.to(heroEmblem, { duration: 0.5, opacity: 1 });
                  gsap.from(heroEmblem.querySelectorAll('svg > *'), { duration: 2, stagger: 0.01, drawSVG: 0 });
                }

                ScrollTrigger.refresh();
              },
            });
          }

        },
      ],

    });
  }

}
