import { gsap, ScrollTrigger, DrawSVGPlugin, Power1 } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

class WalterLays extends HTMLElement {

  constructor() {
    super();

    this.played = false;
    // this.button = this.querySelectorAll('.button');
  }


  connectedCallback() {
    this.setupAnimation();


    ScrollTrigger.create({
      // markers: true,
      trigger: this,
      id: 'walter-lays',
      start: "top 33%",
      end: "bottom top",
      onEnter: (self) => {
        this.playAnimation();
      },
      onEnterBack: (self) => {
        this.playAnimation();
      },
    });
  }


  disconnectedCallback() {
    if (ScrollTrigger.getById('walter-lays')) {
      ScrollTrigger.getById('walter-lays').kill();
    }
  }


  setupAnimation() {
    this.timeline = gsap.timeline({
      paused: true,
      callbackScope: this,
      onComplete: () => {
        this.played = true;
      }
    });


    // BG
    this.timeline.fromTo('[data-name="background"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut });
    // /BG

    // RIVER
    this.timeline.fromTo('[data-name="river"]', { drawSVG: "0%" }, { drawSVG: "100%", duration: 5, ease: Power1.easeInOut }, '-=1');
    // /RIVER


    // LAYS
    this.timeline.fromTo('[data-name="lays-1"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=4');
    this.timeline.fromTo('[data-name="lays-2"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=3.5');
    this.timeline.fromTo('[data-name="lays-3"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=2.5');
    this.timeline.fromTo('[data-name="lays-4"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1.5');
    // /LAYS


    // CITIES
    this.timeline.fromTo('[data-name="burg-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=2');
    this.timeline.fromTo('[data-name="burg-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.75');
    this.timeline.fromTo('[data-name="reil-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="reil-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.75');
    this.timeline.fromTo('[data-name="puenderich-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="puenderich-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.75');
    this.timeline.fromTo('[data-name="briedel-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="briedel-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.75');
    this.timeline.fromTo('[data-name="zell-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="zell-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.75');
    this.timeline.fromTo('[data-name="bullay-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="bullay-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.75');
    // /CITIES

    // WALTER
    this.timeline.fromTo('[data-name="weingut-walter"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="house"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.75');
    // /WALTER

  }


  playAnimation() {
    if (this.played) {
      return;
    }
    this.timeline.play();
  }

}

// Define the new web component
if ('customElements' in window) {
  customElements.define('walter-lays', WalterLays);
}
