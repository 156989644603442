import { gsap, ScrollTrigger, DrawSVGPlugin, Power1 } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

class WalterLaysNavigation extends HTMLElement {

  constructor() {
    super();

    this.played = false;
    this.layId = this.dataset.layId;

    this.navigationTop = this.querySelector('.lays-navigation-toplevel');
    this.navigationTopLinks = this.querySelectorAll('.lays-navigation-toplevel a');
    this.svgWrapper = this.querySelector('svg');
    this.svgLinks = this.querySelectorAll('svg .link');
    this.allLinks = this.querySelectorAll('svg .link, .lays-navigation-toplevel a');
  }


  connectedCallback() {
    if (this.navigationTop != null) {

      // hover on navigationTop
      this.navigationTop.addEventListener('mouseleave', (event) => {
        this.removeAttribute('data-current');
      });

      // hover on SVG links
      this.svgWrapper.addEventListener('mouseleave', (event) => {
        this.removeAttribute('data-current');

      });
      this.allLinks.forEach((svglink) => {
        svglink.addEventListener('mouseenter', (event) => {
          this.dataset.current = event.target.dataset.slug;
        });
        svglink.addEventListener('mouseleave', (event) => {
          this.removeAttribute('data-current');
        });
      });
    }

    this.setupAnimation();

    ScrollTrigger.create({
      // markers: true,
      trigger: this,
      id: 'walter-lays-navigation-' + this.layId,
      start: "top 33%",
      end: "bottom top",
      onEnter: (self) => {
        this.playAnimation();
      },
      onEnterBack: (self) => {
        this.playAnimation();
      },
    });
  }


  disconnectedCallback() {
    if (ScrollTrigger.getById('walter-lays-navigation-' + this.layId)) {
      ScrollTrigger.getById('walter-lays-navigation-' + this.layId).kill();
    }
  }


  setupAnimation() {
    this.timeline = gsap.timeline({
      paused: true,
      callbackScope: this,
      onComplete: () => {
        this.played = true;
      }
    });


    // BG
    this.timeline.fromTo('[data-name="background"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut });
    // /BG

    // RIVER
    this.timeline.fromTo('[data-name="river"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="river"]', { drawSVG: "25%" }, { drawSVG: "100%", duration: 3, ease: Power1.easeInOut }, '<');
    // /RIVER

    // LAY LINES
    this.timeline.fromTo('[data-name="lays-1"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1.5');
    this.timeline.fromTo('[data-name="lays-2"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="lays-3"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="lays-4"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    // /LAY LINES

    // CITIES
    this.timeline.fromTo('[data-name="burg-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="burg-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="reil-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="reil-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="puenderich-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="puenderich-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="briedel-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="briedel-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="zell-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="zell-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="bullay-dot"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="bullay-label"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    // /CITIES

    // WALTER
    this.timeline.fromTo('[data-name="weingut-walter"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    this.timeline.fromTo('[data-name="house"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '<');
    // /WALTER

    // LAYS
    this.timeline.fromTo('[data-name="lay-m"]', { opacity: 0, scale: 0.9 }, { opacity: 1, scale: 1, duration: 0.75, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="lay-s"]', { opacity: 0, scale: 0.9 }, { opacity: 1, scale: 1, duration: 0.75, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="lay-w"]', { opacity: 0, scale: 0.9 }, { opacity: 1, scale: 1, duration: 0.75, ease: Power1.easeInOut }, '-=0.5');
    // /LAYS

  }


  playAnimation() {
    if (this.played) {
      return;
    }
    this.timeline.play();
  }

}

// Define the new web component
if ('customElements' in window) {
  customElements.define('walter-lays-navigation', WalterLaysNavigation);
}
