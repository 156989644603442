export default class Header {

  constructor() {
    this.html = document.querySelector('html');
    this.header = document.querySelector('.header');

    window.addEventListener('resize', (event) => {
      this.resizeHandler();
    });
    window.addEventListener('scroll', (event) => {
      this.scrollHandler();
    });

    this.resizeHandler();
    this.scrollHandler();
  }

  resizeHandler() {
    this.html.style.setProperty('--hh', this.header.clientHeight + 'px');
  }

  scrollHandler() {
    this.header.classList.toggle('has-scrolled', this.html.scrollTop > this.header.clientHeight);

    // // scrolling up
    // if (this.html.scrollTop > this.threshold && this.lastScrollTop < this.html.scrollTop) {
    //   this.headerLogo.classList.add('-translate-y-[100px]');
    // }

    // // scrolling down
    // if (this.lastScrollTop > this.html.scrollTop) {
    //   this.headerLogo.classList.remove('-translate-y-[100px]');
    // }

    // save scroll position
    // this.lastScrollTop = this.html.scrollTop
  }

}
